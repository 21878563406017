<template>

  <div style="display: flex;">
<!--    <el-row>-->
<!--      <el-col :span=""></el-col>-->
<!--      <el-col></el-col>-->

<!--    </el-row>-->
    <el-container>
      <el-card class="box-card">
        <el-row style="margin-top: 10px;">
          <el-input :placeholder="$t('cip.cmn.msg.warning.filterateWarning')" v-model="filterText" class="input-with-select">
            <el-select filterable v-model="select1" slot="append" @change="CEditList" class="st">
              <el-option v-for="item in editList" :key="item.value" :label="item.label" :value="item.value"></el-option>

            </el-select>
          </el-input>
        </el-row>
        <el-tree :data="treeData"
                 :props="defaultProps"
                 @node-click="handleNodeClick"
                 class="filter-tree"
                 ref="tree"
                 :filter-node-method="filterNode"

        >
        </el-tree>

      </el-card>

    </el-container>
    <el-container>
      <el-main>
        <head-layout :head-title="$t('cip.dc.Primordial.title')"
                     :head-btn-options="headBtnOptions"
                     @head-viewBloodRelationship="$router.push('/business/datacenter/DataAssets/PrimordialMold')"
                     @head-add="addTableData"
                     @head-submit="submitRelation"
        ></head-layout>
        <el-table
          :data="tableData"
          border="true"
          :key="Math.random()"
          style="width: 100%">
          <el-table-column type="expand">
            <template slot-scope="props" >
              <el-table :data="props.row.lines" border="true">
                <el-table-column
                  prop="dataSourceName"
                  align="center"
                  :label="$t('cip.dc.Primordial.field.nodeName')"
                />

                <el-table-column
                  prop="tableName"
                  align="center"
                  :label="$t('cip.dc.Primordial.field.tableName')"

                />


              </el-table>
            </template>
          </el-table-column>
          <el-table-column
            prop="dataSourceName"
            align="center"
            :label="$t('cip.dc.Primordial.field.nodeName')"
          />

          <el-table-column
            prop="tableName"
            align="center"
            label="表名"
            :label="$t('cip.dc.Primordial.field.tableName')"

          />

          <el-table-column
            prop="address"
            align="center"
            label="操作"
            :label="$t('cip.dc.Primordial.field.operate')"

          >
            <template slot-scope="{row}">
              <el-button @click="addRelation(row)"> {{$t('cip.dc.Primordial.field.addRelationship') }}</el-button>
            </template>

          </el-table-column>
        </el-table>

      </el-main>
    </el-container>


    <el-row>
      <el-col :span="5">

        <el-dialog :title="nodetit" :visible.sync="dialogVisible" width="30%" @close="Mclose">

          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
            <el-form-item :label="$t('cip.plat.wfDesign.condition.field.type')" prop="type">
              <el-select filterable v-model="ruleForm.type" :placeholder="$t('cip.dc.dataobject.msg.p1')">
                <el-option v-for="item in Typeoptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('cip.plat.wfDesign.condition.field.name')" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <!-- <el-form-item :label="Clabel()" v-if="ruleForm.type != 2">
              <span> {{ ruleForm.fname }} </span>
            </el-form-item> -->
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">{{$t('cancelText')}}</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">{{$t('submitText')}}</el-button>
          </span>
        </el-dialog>


        <el-dialog :title="$t('cip.dc.Primordial.msg.tip')" :visible.sync="dialogVisible2" width="30%">

          <div>{{$t('cip.dc.Primordial.msg.deleteOrNot')}}</div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible2 = false">{{$t('cancelText')}}</el-button>
            <el-button type="primary" @click="MSDel">{{$t('submitText')}}</el-button>
          </span>
        </el-dialog>




      </el-col>
      <el-col :span="19">
        <el-card class="box-card">
<!--          <el-row style="margin-bottom: 20px">-->
<!--&lt;!&ndash;            <el-col>&ndash;&gt;-->
<!--&lt;!&ndash;              <el-button type="primary"   @click="addTableData">添加</el-button>&ndash;&gt;-->
<!--&lt;!&ndash;              <el-button type="primary"  @click="$router.push('PrimordialMold')">查看血缘</el-button>&ndash;&gt;-->
<!--&lt;!&ndash;              <el-button type="primary"  @click="submitRelation">提交</el-button>&ndash;&gt;-->

<!--&lt;!&ndash;            </el-col>&ndash;&gt;-->
<!--          </el-row>-->

        </el-card>


      </el-col>

    </el-row>
    <el-dialog
      :title="$t('cip.dc.PrimordialDComission.title')"
      :visible.sync="dialogVisibleAdd"
      width="80%"
      :before-close="handleClose">
      <el-form :inline="true" :model="form" label-width="120px" :rules="rules" ref="form">
        <el-form-item  :label="$t('DataDirectory.addForm.Datasource')" prop="sourceId">
          <el-select filterable  size="mini" v-model="form.dataSourceId" @change="sourceChange">
            <el-option v-for= ' (item,index) in sourceList' :key="index" :label="item.desc" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('DataDirectory.addForm.Tablename')" prop="metadataTableNameList">
          <el-select filterable  size="mini" v-model="form.tableName" >
            <el-option v-for= ' (item,index) in biaoList' :key="index" :label="item.table_name" :value="item.table_name"/>
          </el-select>
        </el-form-item>
      </el-form>



      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleAdd = false">{{$t('cancelText')}}</el-button>
          <el-button type="primary" @click="addTableDataList">{{$t('submitText')}}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('cip.dc.Primordial.title')" :visible.sync="dialogTableVisible">
      <el-table :data="relationData" border ref="multipleTable"  @select='relationSelect'
                @select-all="relationSelect">
        <el-table-column
          type="selection"
          align="center"

          width="50">
        </el-table-column>
        <el-table-column property="id" :label="$t('cip.dc.Primordial.field.number')"></el-table-column>
        <el-table-column property="dataSourceName" :label="$t('cip.dc.Primordial.field.nodeName')"></el-table-column>

        <el-table-column property="tableName" :label="$t('cip.dc.Primordial.field.tableName')" ></el-table-column>

      </el-table>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogTableVisible = false">{{$t('cancelText')}}</el-button>
          <el-button type="primary" @click="confirmRelation">{{$t('submitText')}}</el-button>
      </span>
    </el-dialog>



  </div>

</template>
<script>

import { getBloodTree, databloodDetail, SaveDataBloodType, DelBloodType, AddDataBlood, DelDataBlood } from "@/api/dataAcquisition/DataAssets";
import Template from '@/views/message/template/list'
import {listAll} from '@/api/dataAcquisition/DataServices'
import {modelList} from '@/api/dataAcquisition/DataDirectory'
// import eventBus from "./src/utils/eventBus"

export default {
  props: {

  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  data() {

    return {
      sourceList:[],
      nodetit:'',
      headBtnOptions: [],
      confirmNode:{},
      relationSelectList:[],
      dialogVisibleAdd:false,
      dialogTableVisible:false,
      nodeDatas:[],
      form: {
        index:0,
        id: '',
        dataSourceName:'',
        dataSourceId:'',
        tableName:''

      },
      biaoList:[],
      tableData: [],
      relationData:[],
      threeNode:{},

      dqjd:"", //当前节点
      select1:"",
      editList:[
        {label:this.$t("cip.cmn.btn.addBtn"),value:0},
        {label:this.$t("cip.cmn.btn.editBtn"),value:1},
        {label:this.$t("cip.cmn.btn.delBtn"),value:2},
      ],
      Typeoptions: [
        { label: "目录", value: 0 },
        
        { label: "数据血缘", value: 1 },
      ],
      dialogVisible: false,
      ruleForm: { name: "", fid: "0", fname: "", id: "", type: 0 },
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'blur' },
        ],
      },
      visible: false,
      dialogVisible2: false,
      delid: "",
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      showG: false,
      filterText: '',
    }
  },

  components: {
    Template

  },
  created() {

  },
  mounted() {
    this.MgetBloodTree()
    this.sourceListFn()
  },
  methods: {
    sourceListFn(){
      listAll({}).then((res)=>{
        this.sourceList = res.data.data;
      })
    },
    //todo 数据源改变查询数据表
    sourceChange(value){
      this.form.metadataTableNameList = [];

      let source = this.sourceList.find(item=>item.id == value);
      this.form.dataSourceName=source.desc
      this.source = source;
      modelList({
        host_id:source.name,
        dbType:source.dbtype,
        id:source.id,
      }).then((res)=>{
        this.biaoList = res.data.data;
      })
    },
    submitRelation(){
      this.$confirm(this.$t('cip.dc.Primordial.msg.submission'), this.$t('cip.dc.Primordial.msg.tip'), {
        confirmButtonText: this.$t('submitText'),
        cancelButtonText: this.$t('cancelText'),
        type: 'warning'
      }).then(() => {

        AddDataBlood({dataBloodTypeId:this.threeNode.parentId, id: this.threeNode.dataBloodId,name:this.threeNode.name,nodeDatas:this.tableData}).then(res => {
          if (res.status == 200) {

            this.$message.success(this.$t('cip.dc.Primordial.msg.successfully'));
            databloodDetail( this.threeNode.dataBloodId).then(res => {
              if (res.status == 200) {
                // console.log('详情',res,'sss')

                localStorage.setItem('PRIMORD-DATA',res.data.data.blood)
                this.tableData=JSON.parse(res.data.data.bloodData)
                console.log(this.tableData,'tt')
                // console.log(JSON.parse(  res.data.data.blood),res,'ssss')
              }
            })
            // this.tableData=[]
            // this.MgetBloodTree()

          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('cip.dc.Primordial.msg.Canceled')
        });
      });

    },
    confirmRelation(){
      // let node={
      //   ...this.confirmNode,
      //   // id: this.confirmNode.id,
      //   // tableName: this.confirmNode.tableName,
      //   // dataSourceName: this.confirmNode.dataSourceName,
      //   // dataSourceId: this.confirmNode.dataSourceId,
      //
      //   lines:[],
      // }
      // console.log(node,this.confirmNode,'this.relationSelectList')
      // const newRelatingData=this.relationSelectList.map(item=>item.id='')
      let setData=[]
      this.tableData.map(item=>{
        if(item.id==this.confirmNode.id){
          console.log(111)
          if(!Array.isArray(item.lines)){
            item.lines=[]
          }
          if(this.relationSelectList.length<1){
            item.lines=[]
          }
          console.log(this.relationSelectList,'this.relationSelectList')
          this.relationSelectList.map(val=>{
            console.log(222)
            let data={
              from:this.confirmNode.id,
              to:val.id,
              dataSourceId:val.dataSourceId,
              dataSourceName:val.dataSourceName,
              tableName:val.tableName
            }
            console.log(data)

            setData.push(data)
            console.log( setData,data,' this.setData')
            item.lines=setData
            this.$forceUpdate()
             // if(!item.lines.some(som=>som.to==data.to)){
             //  item.lines.push(data)
             // }
          })

        }
      })


      this.confirmNode={}
      this.relationSelectList=[]
      this.dialogTableVisible=false
      // // node.children=childrenData
      // if( this.nodeDatas.some(item=>item.id==node.id)){
      //   this.nodeDatas.map(item=>{
      //     if(item.id==node.id){
      //       console.log(item.id)
      //       return item=node
      //     }
      //   })
      // }else {
      //   this.nodeDatas.push(node)
      // }

    },
    relationSelect(selection, row){
      this.relationSelectList=selection

    },
    handleClose(done) {
      this.$confirm(this.$t('cip.dc.Primordial.msg.ConfirmClose'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    addTableData(){
      if(this.threeNode.parentId=='0') return this.$message.error(this.$t('cip.dc.Primordial.msg.bloodAdd'))

      this.form.id='N'+Date.now()
      this.dialogVisibleAdd=true
    },
    addRelation(row){
      this.confirmNode=row

      this.relationData=[...this.tableData.filter(item=>item.id!=row.id)]
      console.log(this.relationData,'this.relationData')

      this.dialogTableVisible=true
      if(row.lines&&row.lines.length>0){


          this.$nextTick(()=>{
            row.lines.map(val=>{
              this.relationData.forEach((item,index)=>{
                if(val.to==item.id){
                  this.$refs.multipleTable.toggleRowSelection(item);
                  this.relationSelectList.push(item)
                }

              })
            })


          })



      }


    },
    addTableDataList(){
      this.form.index=this.tableData.length
      this.$set(this.tableData, this.form.index, { id: this.form.id, tableName: this.form.tableName, dataSourceName: this.form.dataSourceName,dataSourceId:this.form.dataSourceId })
      this.form.index++
      this.form.id=''
      this.form.tableName=''
      this.form.dataSourceName=''
      this.form.dataSourceId=''
      this.dialogVisibleAdd=false
    },

    Clabel(){
      if(this.select1 === 0){
        return this.$t('cip.dc.Primordial.msg.Parent')
      }
      if(this.select1 === 1){
        return this.$t('cip.dc.Primordial.msg.TreeDirectory')
      }
    },
    CEditList(e){
       console.log(e);
       this.select1 = ""
       if(e === 0){
        if(!this.dqjd.dataBloodId){
          this.Madd()
        }else{
          this.select1 = ""
          this.$message({
              message: this.$t('cip.dc.Primordial.msg.relationship'),
              type: 'warning'
            });
        }


       }
       if(e===1){
        if(this.dqjd){
          if(!this.dqjd.dataBloodId){
            this.Medit()
          }else{
          //   this.select1 = ""
          // this.$message({
          //     message: this.$t('cip.dc.Primordial.msg.deleteTheDetails'),
          //     type: 'warning'
          //   });
          console.log(this.dqjd,"rrrrrrrrrrrrr");
          this.Medit2()
          }

        }else{
          this.select1 = ""
          this.$message({
              message: this.$t('cip.dc.Primordial.msg.directoryToModify'),
              type: 'warning'
            });
        }

       }
       if(e===2){
        if(this.dqjd){
          if(!this.dqjd.dataBloodId){
          this.Mdel()
        }else{
          // this.select1 = ""
          // this.$message({
          //     message: '数据血缘请在右侧详情编辑和删除！',
          //     type: 'warning'
          //   });
          DelDataBlood({parentId:this.dqjd.parentId,typeName:this.dqjd.name,id:this.dqjd.dataBloodId}).then(res=>{
            this.MgetBloodTree()
          })
        }
        }else{
          this.select1 = ""
          this.$message({
              message: this.$t('cip.dc.PrimordialDComission.msg1'),
              type: 'warning'
            });
        }


       }
    },
    filterNode(value, treeData) {
      console.log(value,"ssssss", treeData.label);
      if (!value) return true;
      return treeData.name.indexOf(value) !== -1;
    },
    //树
    MgetBloodTree() {
      getBloodTree().then(res => {
        if (res.status == 200) {
          this.treeData = res.data.data
        }
      })
    },
    //添加树节点
    Madd(a, b) {
      // console.log(a, "ssssssss", b);
      // this.ruleForm.fid = b.id
      // this.ruleForm.fname = b.name
      // this.ruleForm.id = undefined
      this.nodetit=this.$t('cip.dc.Primordial.msg.NewNode')
      this.dialogVisible = true

      this.ruleForm.fid = this.dqjd.id
      this.ruleForm.fname = this.dqjd.name
      this.ruleForm.id = undefined
    },
    Mclose() {
      this.ruleForm.fid = "0"
      this.ruleForm.id = ""
      this.ruleForm.fname = ""
      this.ruleForm.name = ""
      this.ruleForm.type = 0
      this.select1 = ""
    },
    //修改树节点
    Medit(a, b) {
      this.nodetit=this.$t('cip.dc.Primordial.msg.editNode')
      this.dialogVisible = true
      this.ruleForm.id = this.dqjd.id
      this.ruleForm.name = this.dqjd.name
      this.ruleForm.fid = this.dqjd.parentId
      var abb = JSON.parse(JSON.stringify(this.treeData))
        var alltree = this.flatten(abb)
        var cc = this.FinTfather(alltree, this.ruleForm.fid).reverse()
        this.ruleForm.fname = ""
        cc.forEach((item) => {
          this.ruleForm.fname += item + '/'
        })
        this.ruleForm.fname += this.ruleForm.name
    },
    Medit2() {
      this.nodetit=this.$t('cip.dc.Primordial.msg.editNode')
      this.dialogVisible = true
      this.ruleForm.id = this.dqjd.id
      this.ruleForm.name = this.dqjd.name
      this.ruleForm.fid = this.dqjd.parentId
      this.ruleForm.type = 1

    },
    //删除树节点
    Mdel(a, b) {
      // this.delid = b.id
      this.delid = this.dqjd.id
      this.dialogVisible2 = true
    },
    MSDel() {
      this.dialogVisible2 = true
      DelBloodType({ id: this.delid }).then(res => {
        if (res.status == 200) {
          this.dialogVisible2 = false
          this.MgetBloodTree()
        }
      })

    },
    //点击树节点显示血缘图
    handleNodeClick(e) {
      console.log(e,"sdasdasdasd");
      this.threeNode=e
      this.dqjd = e
      if (e.dataBloodId) {
        sessionStorage.setItem("Bloodid", JSON.stringify(e));
        databloodDetail(e.dataBloodId).then(res => {
          if (res.status == 200) {

            localStorage.setItem('PRIMORD-DATA',res.data.data.blood)
            if(res.data.data.bloodData){
              this.tableData=JSON.parse(res.data.data.bloodData)
              this.nodeDatas=JSON.parse(res.data.data.bloodData)
              console.log(this.tableData,'this.tableData')

            }else{
              this.tableData=[]
              this.nodeDatas=[]
            }


          }
        })
        this.headBtnOptions=[{
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add",
          type: "button",
          icon: ""
        },
          {
            label: this.$t('cip.cmn.btn.BloodRelationshipBtn'),
            emit: "head-viewBloodRelationship",
            type: "button",
            icon: ""
          }, {
            label: this.$t('cip.cmn.btn.submitBtn'),
            emit: "head-submit",
            type: "button",
            icon: ""
          }]
        this.showG = true
      } else {
        this.tableData=[]
        this.headBtnOptions=[]
        this.showG = false
      }
      //  dataBloodId
    },


    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.type == 0) {
            SaveDataBloodType({ typeName: this.ruleForm.name, parentId: this.ruleForm.fid, id: this.ruleForm.id }).then(res => {
              if (res.status == 200) {
                console.log(res);
                this.MgetBloodTree()
                this.dialogVisible = false
              }

            })
          }
          if (this.ruleForm.type == 2) {
            console.log("nnnnnnnnnnnnnnnnnnnnn2222222222222222");
            SaveDataBloodType({ typeName: this.ruleForm.name, parentId: undefined, id: this.ruleForm.id }).then(res => {
              if (res.status == 200) {
                console.log(res);
                this.MgetBloodTree()
                this.dialogVisible = false
              }

            })
          }
          //新增血缘
          if (this.ruleForm.type == 1) {
            console.log("nnnnnnnnnnnnnnnnnnnnn");
            AddDataBlood({ name: this.ruleForm.name, dataBloodTypeId: this.threeNode.id,parentId: this.ruleForm.fid, }).then(res => {
              if (res.status == 200) {
                console.log(res);
                this.MgetBloodTree()
                this.dialogVisible = false
              }

            })
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },









     //扁平化 树
     flatten(arr) {
      let result = [];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children && arr[i].children.length > 0) {
          result = result.concat(this.flatten(arr[i].children));
          arr[i].children = []
          result = result.concat(arr[i])
        } else {
          result.push(arr[i]);
        }
      }
      return result;
    },

    //寻找父级
    FinTfather(arr, fid) {
      let arr2 = []
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id == fid) {
          if (arr[i].parentId != "0") {
            fid = arr[i].parentId
            arr2.push(arr[i].name)
            this.FinTfather(arr, fid)
          } else {
            arr2.push(arr[i].name)
          }

        }
      }
      return arr2;
    },







  },
  beforeDestroy() {

  }
}
</script>




<style scoped>
#jsmind_container {
  width: 100%;
  height: 100vh;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.box-card {
  height: 100vh;
}
.el-select .el-input {
    width: 130px;
  }
.input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
.st::v-deep .el-input__suffix {
    right: -30px;
}

</style>

